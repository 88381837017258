<template>
  <div id='post-comment-reply-form' class='mt-4' ref="commentReplyForm">
    <div class='flex gap-x-4'>
      <img v-lazy="state.curr_profile.avatar_url" :alt="state.curr_profile.display_name" class='rounded-full w-6 h-6'>
      <div class='flex flex-col flex-1'>
        <textarea class='w-full h-12 p-2 text-xs text-gray-10 focus:outline-none w-full default-input-klass'
          :value='commentContent' @keyup='updateCommentContent' />
        <div class='flex -mx-1 my-1'>
          <div v-for='(file, index) in commentImageFiles' class='relative'>
            <div
              @click.prevent="commentImgDelete(index)"
              class='delete-icon absolute w-5 h-5 right-0 top-0 cursor-pointer'
            ></div>
            <div
              v-lazy:background-image="parseImgUrl(file)"
              class='image rounded-8px w-10 h-10 bg-cover m-1'
            ></div>
          </div>
        </div>
        <div class='flex justify-between'>
          <label class="flex items-center btn btn-sm btn-secondary rounded-full px-0">
            <span class="image-icon w-8 h-8 -my-2"></span>
            <input id="comment_images"
                   class="hidden"
                   multiple="multiple"
                   accept="image/jpeg,image/png,image/jpg"
                   type="file"
                   v-on:change="commentImgUpload">
          </label>
          <span>
            <button class="px-2 btn-sm cancel-btn btn mr-2"
                    @click.prevent="cancelReplyComment(comment)">
              {{ $t('comment_form.cancel') }}
            </button>
            <button v-if='uploading'
                    id='reply-comment'
                    class="px-2 btn-sm rounded-full btn btn-secondary"
                    :disabled="true">
              {{ $t('comment_form.uploading') }}
            </button>
            <button v-else
                    id='reply-comment'
                    class="px-2 btn-sm rounded-full btn btn-secondary"
                    @click.prevent="submitReplyComment"
                    :disabled="!commentContent">
              {{ $t('comment_form.reply') }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { state } from "../stores/postState";

export default defineComponent({
  props: ['commentState', 'comment', 'fetchCommentItemData'],
  data() {
    return {
      uploading: false,
      commentContent: '',
      commentImageFiles: [],

      state,
    };
  },
  mounted() {
    const replyForm = this.$refs.commentReplyForm

    if (this.commentState.reply_comment_id && replyForm) {
      replyForm.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  },
  methods: {
    commentImgUpload(e) {
      e.target.files.forEach((file) => {
        this.commentImageFiles.push(file)
      })
    },
    commentImgDelete(index) {
      this.commentImageFiles.splice(index, 1);
    },
    parseImgUrl(file){
      return URL.createObjectURL(file)
    },
    updateCommentContent(e) {
      this.commentContent = e.target.value
    },
    cancelReplyComment(comment) {
      document.getElementById(`comment-${comment.id}`).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      this.commentContent = ''
      this.commentImageFiles = []
      this.commentState.reply_comment_id = ''
    },
    async submitReplyComment(e) {
      this.uploading = true
      const requestUrl = `/posts/${this.state.post_id}/comments`;
      const formData = new FormData()

      formData.append('comment[parent_id]', this.commentState.reply_comment_id)
      formData.append('comment[content]', this.commentContent)

      this.commentImageFiles.forEach(function(file) {
        formData.append('comment[images_attributes][][file]', file)
      })

      fetch(requestUrl, {
        method: 'post',
        headers: {
          "X-CSRF-Token": this.state.CSRFtoken
        },
        body: formData,
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then(errData =>{
            throw new Error(errData.message || 'Error in submission');
          })
        }
      })
      .then((data) => {
        console.log('post comment!!!', data)
        this.uploading = false
        this.commentContent = '';
        this.commentImageFiles = []

        this.fetchCommentItemData();
      })
    }
  }
});
</script>
../stores/postState