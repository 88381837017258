<template>
  <div class="w-full flex flex-wrap flex justify-center"> 
    <div v-if="items.length > 0" id="profile-follow-collection" class="w-full">
      <div id="profile-item-collection" class="flex w-full">
        <div v-for="profile in items" :key="profile.id" class="profile-item-container flex flex-col items-center justify-center bg-gradient-to-b">
          <div class="profile-item ">
            <a
              :href="profile.public_url"
              class="block rounded-full overflow-hidden"
            >
              <img
                v-lazy="profile.avatar_url"
                :alt="profile.display_name"
                class="image"
              />
            </a>
            <div class="profile-name w-full py-2">
              <a
                class="block text-grey-5 text-14px truncate font-medium"
                :title="profile.display_name"
                :href="profile.public_url"
              >
                {{ profile.display_name }}
              </a>
            </div>
            <template v-if="profile.followable != null">
              <div
                v-if="profile.followable"
                class="profile-follow-btn follow"
                @click="followBtnClick(profile)"
              >
                {{ $t('profile_collection.follow') }}
              </div>
              <div
                v-else
                class="profile-follow-btn unfollow"
                @click="unfollowBtnClick(profile)"
              >
                {{ $t('profile_collection.following') }}
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-if="loading" class="text-center post-item-loading">
        {{ $t('post_index_collection.loading') }}
      </div>
    </div>
    <template v-else>
        <div v-if="scope === 'follower'"
            class='text-16px font-bold text-center my-20 md:my-40'>
          {{ $t('public_profiles.collection_empty.follower') }}
        </div>
        <div v-else 
            class='text-16px font-bold text-center my-20 md:my-40'>
          {{ $t('public_profiles.collection_empty.following') }}
        </div>
    </template>
  </div>
</template>

<script>

import { defineComponent } from "vue";
import i18next from "i18next";

export default defineComponent({
  props: {
    scope: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      currPage: 0,
      autoload: true,
      // collection list
      loading: false,
      username: '',
      itemsCols: 5,
      itemsRows: 2,
      itemsCount: 10,
      items_not_found: false,
      items_data: [],
      items: [],
      current_collection:[]
    };
  },
  mounted() {
    this.setupItemColsCount()

    this.username = this.getUsernameFromURL();
    this.fetchItemData(this.currPage, this.itemsCount*2)
    this.currPage += 1

    window.addEventListener("scroll", () => {
      if (this.autoload == false) {
        return;
      }

      const scrollableHeight =
        document.body.scrollHeight - window.innerHeight - 150;
      const scrolledDistance = window.scrollY;

      if (scrolledDistance >= scrollableHeight) {
        if (this.loading == false) {
          this.fetchItemData();
        }
      }
    });
  },
  methods: {
    getUsernameFromURL() {
      const url = window.location.href;
      const match = this.scope === 'follower' ? url.match(/\/p\/([^\/]+)\/followers/) : url.match(/\/p\/([^\/]+)\/followings/);
      return match ? match[1] : '';
    },
    followBtnClick(profile) {
      this.followCreator(profile);
    },
    unfollowBtnClick(profile) {
      this.unfollowCreator(profile);
    },
    async followCreator(profile) {
      try {
        const url = "/api/v1/profiles/" + profile.username + "/follow";
        const response = await fetch(url, { method: "POST" });

        if (response.ok) {
          const data = await response.json();

          if (data.success) {
            const profileIndex = this.items.indexOf(profile);
            this.items[profileIndex].followable = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async unfollowCreator(profile) {
      try {
        const url = "/api/v1/profiles/" + profile.username + "/unfollow"
        const response = await fetch(url, {method: 'DELETE'})

        if (response.ok) {
          const data = await response.json();

          if (data.success) {
            const profileIndex = this.items.indexOf(profile)
            this.items[profileIndex].followable = true
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchItemData(currPage, perPage) {
      const url = "/api/v1/profiles/" + this.username + "/get_" + this.scope
      this.loading = true;

      // 資料要往後拉，所以 +1
      let curr_params = {
        page: (currPage || this.currPage) + 1,
        per_page: perPage || this.itemsCount
      };
      
      try {
        const response = await fetch(
          url + "?" + new URLSearchParams(curr_params)
        );

        if (response.ok) {
          this.loading = false;

          const data = await response.json();
          const itemsData = data['data']

          // 如果 params page > resp total_page
          // 等於拉到底了，就不用 autoload 了      
          if (curr_params["page"] > data["total_pages"]) {
            this.autoload = false;
          } else if (itemsData.length > 0) {
            // 如果有資料就要塞到 items 裏面，並更新頁碼
            this.items.push(...itemsData);
            this.currPage++;
          }
        }
      } catch (error) {
        console.log(error);
        this.items = [];
        console.log('error');
        this.autoload = false;
      }
    },

    setupItemColsCount() {
      if (window.innerWidth >= 1920) {
        this.itemsCols = 6;
      } else if (window.innerWidth >= 1536) {
        this.itemsCols = 5;
      } else if (window.innerWidth >= 1024) {
        this.itemsCols = 4;
      } else if (window.innerWidth >= 780) {
        this.itemsCols = 3;
      } else if (window.innerWidth >= 705) {
        this.itemsCols = 2;
      } else {
        this.itemsCols = 1;
      }

      if (this.itemsCols != 1) {
        this.itemsCount = this.itemsCols * this.itemsRows;
      } else {
        this.itemsCount = 10;
      }
    },
  }
})
</script>
